<template>
  <v-card height="380px">
    <v-card-title primary-title class="layout justify-center">
      <div class="headline" v-html="$t('Common.contactus_title')"></div>
    </v-card-title>
    <v-card-text class="text-center" v-html="$t('Common.contactus_sub_title')">
    </v-card-text>
    <v-list class="transparent">
      <v-list-item>
        <v-list-item-action>
          <v-icon color="takein_logo">email</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Email <a>info@takein.com</a></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <a href="https://facebook.com/TakeInLocal" target="_blank">
            <v-icon color="takein_logo">fab fa-facebook</v-icon>
          </a>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Facebook
            <a href="https://facebook.com/TakeinLocal" target="_blank"
              >@TakeInLocal</a
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <a href="https://twitter.com/TakeInLocal" target="_blank">
            <v-icon color="takein_logo">fab fa-twitter</v-icon>
          </a>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            >Twitter
            <a href="https://twitter.com/TakeInLocal" target="_blank"
              >@TakeInLocal</a
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <a href="https://www.instagram.com/TakeInLocal/" target="_blank">
            <v-icon color="takein_logo">fab fa-instagram</v-icon>
          </a>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Instagram
            <a href="https://www.instagram.com/TakeInLocal/" target="_blank"
              >@TakeInLocal</a
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-icon color="takein_logo">phone</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Phone (877) 929-9006
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
  export default {
    props: ["type"],
  };
</script>
